import { z } from 'zod'
import {
  ActionCategory,
  ActionData,
  actionDataSchema,
} from './useActions.types'
import { subtaskSchema } from './useSubtask.types'

export * from './useActions.types'

export enum CommentTargetType {
  FootprintDataPointValue = 'FootprintDataPointValue',
  CompanyAction = 'CompanyAction',
}

export enum CommentStatus {
  Rejected = 'rejected',
  Approved = 'approved',
  Requested = 'requested',
}

export const commentItemSchema = z.strictObject({
  id: z.number(),
  targetId: z.number().nullable(),
  targetType: z.nativeEnum(CommentTargetType).nullable(),
  content: z.string().nullable(),
  createdAt: z.coerce.date(),
  userId: z.number().nullable(),
  status: z.nativeEnum(CommentStatus).nullable(),
})
export type CommentItem = z.infer<typeof commentItemSchema>

export const relatedItemSchema = z.strictObject({
  id: z.number(),
  active: z.boolean(),
  title: z.string(),
  category: z.nativeEnum(ActionCategory),
})
export type RelatedItem = z.infer<typeof relatedItemSchema>

export const actionFullDataSchema = actionDataSchema.extend({
  templateActionId: z.number().nullable(),
  comments: z.array(commentItemSchema),
  learnMoreUrl: z.string().nullable(),
  relatedCompanyActions: z.array(relatedItemSchema),
  subtasks: z.array(subtaskSchema),
  locationId: z.number().nullable(),
  note: z.string().nullable(),
})
export type ActionFullData = z.infer<typeof actionFullDataSchema>

export interface ActionForBackendData extends ActionData {
  note: string | null
  subtasksAttributes: {
    title: string
    position: number
    _destroy?: boolean
  }[]
}

export interface ActionMove extends Record<string, number | undefined> {
  targetYear?: number
  targetQuarter?: number
  targetOrder?: number
  actionIdBefore?: number
  actionIdAfter?: number
}
